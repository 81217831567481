import dynamic from "next/dynamic";
import { SortedTilesProps } from "./SortedTiles";

const SortedTilesServer = dynamic(() => import("./SortedTilesServer"));
const SortedTilesClient = dynamic(() => import("./SortedTilesClient"));

const SortedTilesDynamic = ({ isClientRendered, ...rest }: SortedTilesProps) => {
  return isClientRendered ? <SortedTilesClient {...rest} /> : <SortedTilesServer {...rest} />;
};

export default SortedTilesDynamic;
