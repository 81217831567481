"use client";

import { motion } from "framer-motion";
import React, { FC, useEffect, useRef, useState } from "react";
import Balancer from "react-wrap-balancer";
import { Tiles } from "types/apiTypes";
import Container from "components/Tiles/commonComponents/Container";
import NinjaIcon from "./NinjaIcon";

export type CitationOverlayProps = {
  introMessage?: string;
  tiles?: Tiles;
  isEnabled?: boolean;
  children?: React.ReactNode;
};

const textVariants = {
  initial: {
    opacity: 0
  },
  visible: {
    opacity: 1
  }
};

const CitationOverlay: FC<CitationOverlayProps> = ({
  tiles,
  introMessage,
  isEnabled,
  children
}: CitationOverlayProps) => {
  const [citation, setCitation] = useState(false);
  const initCitationStateUpdated = useRef(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    const citationTimeout1 = setTimeout(() => setCitation(false), 8000);

    return () => clearTimeout(citationTimeout1);
  }, [setCitation]);

  useEffect(() => {
    if (!tiles) return;
    let ids: NodeJS.Timeout[] = [];
    if (tiles.intro_display) {
      setCitation(true);
      ids = [setTimeout(() => setCitation(false), 8000)];
    }
    initCitationStateUpdated.current = true;
    return () => ids.forEach((id) => clearTimeout(id));
  }, [tiles]);

  if (!isEnabled) {
    return <>{children}</>;
  }

  return (
    <>
      <motion.div
        className="relative z-10 flex w-full items-center justify-center bg-[#171717]"
        variants={{
          initial: {
            opacity: 1,
            height: "100%",
            zIndex: 10
          },
          invisible: {
            opacity: 0,
            height: 0
          }
        }}
        transition={{
          duration: 0.6
        }}
        initial="initial"
        animate={citation && initCitationStateUpdated ? "initial" : "invisible"}
        onAnimationComplete={(defination) => {
          if (defination === "invisible") {
            setAnimationCompleted(true);
          }
        }}
      >
        <Container>
          <motion.div
            variants={textVariants}
            initial="initial"
            animate="visible"
            transition={{
              delay: 8.0,
              duration: 1.2
            }}
          ></motion.div>
          <motion.p
            className="mb-4 mt-4 font-matter text-3xlt font-medium text-white @2xl:text-4xlt  @3xl:my-0 @3xl:text-4xlt"
            variants={textVariants}
            initial="initial"
            animate="visible"
            transition={{
              delay: 1.0,
              duration: 1.2
            }}
          >
            <Balancer>{introMessage}</Balancer>
          </motion.p>
          <motion.p
            className="text-right text-base text-white"
            initial="initial"
            animate="visible"
            variants={textVariants}
            transition={{
              delay: 3.0,
              duration: 1.2
            }}
          >
            <NinjaIcon className="mb-2 ml-auto w-[250px] fill-current text-white" />
          </motion.p>
        </Container>
      </motion.div>
      <motion.div
        variants={{
          initial: {
            opacity: 0,
            display: "block"
          },
          visible: {
            opacity: 1,
            display: "block"
          }
        }}
        initial="initial"
        animate={initCitationStateUpdated.current && !citation && animationCompleted ? "visible" : "initial"}
        transition={{
          duration: 0.5
        }}
      >
        {children}
      </motion.div>
    </>
  );
};

export default CitationOverlay;
