import clsx from "clsx";
import { FC } from "react";

import { HTMLAttributes } from "react";

type Props = HTMLAttributes<HTMLDivElement>;

const Container: FC<Props> = ({ className, children }: Props) => {
  return <div className={clsx("mx-auto max-w-screen-md px-4", className)}>{children}</div>;
};

export default Container;
