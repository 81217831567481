import { useCallback } from "react";

const useConfetti = () => {
  const showConfetti = useCallback(() => {
    if (typeof window === "undefined") return;
    // @ts-ignore
    window.confetti?.({
      particleCount: 500,
      spread: 700,
      origin: { y: 0.5 }
    });
  }, []);

  return { showConfetti };
};

export default useConfetti;
